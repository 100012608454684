//@ts-nocheck
import Cost from 'containers/const/Cost';
import styles from 'page/cart/confirmation/orderBlock/OrderBlock.module.css';
import messages from 'page/cart/confirmation/orderBlock/OrderBlockMessages';
import OrderList from 'page/cart/confirmation/orderBlock/orderList/OrderList';
import ProductList from 'page/cart/confirmation/orderBlock/productList/ProductList';
import Certificates from 'page/orders/component/certificates/Certificates';
import Coupons from 'page/orders/component/coupons/Coupons';
import Discounts from 'page/orders/component/discounts/Discounts';
import LoyaltySummary from 'page/orders/component/loyaltySummary/LoyaltySummary';
import * as React from 'react';
import { injectIntl } from 'react-intl';

import ExternalProductList from './productList/ExternalProductList';

class OrderBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        this.refreshLoyalty = this.props.handleCalculate;
    }

    handleBonusesChanged = (value) => {
        this.props.handleBonusesChanged(value);
    };

    handleCoupons = (value) => {
        this.props.handleCoupons(value);
    };

    handleCertificates = (value) => {
        this.props.handleCertificates(value);
    };

    renderCoupons = () => {
        const { onlinePaymentTypes, selectedPaymentType, saleAction } = this.props;
        if (onlinePaymentTypes[selectedPaymentType] && saleAction?.coupons) {
            return <Coupons handleCoupons={this.handleCoupons} />;
        }
        return null;
    };

    renderCertificates = () => {
        const { onlinePaymentTypes, selectedPaymentType, saleAction, currency } = this.props;
        if (onlinePaymentTypes[selectedPaymentType] && saleAction?.certificates) {
            return (
                <div>
                    <Certificates handleCertificates={this.handleCertificates} currency={currency} />
                </div>
            );
        }
        return null;
    };

    renderLoyaltyBlock = () => {
        const { cart, cartOrderType, currency } = this.props;
        const hasDiscount =
            (cartOrderType.analyzes ||
                cartOrderType.reorder ||
                cartOrderType.paymentPreorder ||
                cartOrderType.externalPreorder ||
                cartOrderType.externalReorder) &&
            cart.costWithDiscount &&
            cart.costWithDiscount !== cart.cost;

        if (hasDiscount || cart?.hasAppliedCertificates) {
            return (
                <div>
                    <Cost defaultValue='-' amount={cart.costWithDiscount} currency={currency} />
                </div>
            );
        }
        return null;
    };

    renderPromotions = () => {
        const { saleAction } = this.props;
        if (saleAction?.coupons || saleAction?.certificates) {
            return (
                <div className={styles.promotions}>
                    {this.renderCoupons()}
                    {this.renderCertificates()}
                </div>
            );
        }
        return null;
    };

    render() {
        const { cart, intl, checkups, cartOrderType, saleAction, services, cost, currency, loading, isExternal } = this.props;

        if (!isExternal && !cart?.products && !checkups?.valid) {
            return null;
        }

        const orderListTitle = cartOrderType.reorder ? messages.reorderListTitle : messages.orderListTitle;

        return (
            <div className={styles.orderListBlock}>
                <h2 className={styles.orderListTitle}>{intl.formatMessage(orderListTitle)}</h2>

                <OrderList loading={loading} cost={cost} saleAction={saleAction} currency={currency} cartOrderType={cartOrderType} services={services} />

                {isExternal ? (
                    <ExternalProductList orderProducts={cart.orderProducts} cartOrderType={cartOrderType} currency={currency} />
                ) : (
                    <ProductList cart={cart} checkups={checkups?.valid} cartOrderType={cartOrderType} currency={currency} />
                )}

                {saleAction && (
                    <>
                        <LoyaltySummary saleAction={saleAction} handleBonusesChanged={this.handleBonusesChanged} />
                        <Discounts saleAction={saleAction} />
                        {this.renderPromotions()}
                    </>
                )}

                {!loading && (
                    <div className={styles.orderListTotal}>
                        <div className={styles.orderListTotalTitle}>{intl.formatMessage(messages.totalTitle)}</div>
                        <div className={styles.orderListTotalPrice}>
                            {this.renderLoyaltyBlock()}
                            <div>
                                <Cost defaultValue='-' amount={cost.totalCost} currency={currency} />
                            </div>
                        </div>
                    </div>
                )}

                {cartOrderType.checkups && <div className={styles.checkupInformation}>{intl.formatMessage(messages.checkupInformation)}</div>}
            </div>
        );
    }
}

export default injectIntl(OrderBlock);
