export const OrderType = {
    /**
     * Внешний предзаказ
     */
    EXTERNAL_PREORDER: 'EXTERNAL_PREORDER',

    /**
     * Внешний дозаказ
     */
    EXTERNAL_REORDER: 'EXTERNAL_REORDER',

    /**
     * Заказ
     */
    ORDER: 'ORDER',

    /**
     * Предзаказ
     */
    PREORDER: 'PREORDER',

    /**
     * ДОЗАКАЗ
     */
    REORDER: 'REORDER'
};
