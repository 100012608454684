//@ts-nocheck
import * as cartClient from 'client/CartClient';
import * as homeVisitClient from 'client/HomeVisitClient';
import { OfficeType } from 'constants/officeType';
import { PaymentType } from 'constants/paymentType';
import { authSelector } from 'redux/auth/selectors';
import {
    ADD_PRODUCT,
    ADD_RECOMMENDED_PRODUCT,
    ADD_RECOMMENDED_PROFILE,
    CALCULATE_CHECKUPS,
    CALCULATE_EXTERNAL_PREORDER,
    CALCULATE_EXTERNAL_REORDER,
    CALCULATE_HOME_VISIT,
    CALCULATE_PAYMENT_PREORDER,
    CALCULATE_REORDER,
    CHECK_APPOINTMENT,
    CHECK_PRODUCTS_AVAILABLE,
    CHECK_REORDER_AVAILABILITY,
    CLEAR_APPOINTMENT,
    CLEAR_CHECKUPS_DATA,
    CLEAR_HOME_VISIT_DATA,
    CLEAR_OFFICE_DATA,
    CLEAR_REORDER_CART,
    DELETE_INVALID_ANALYZES,
    DELETE_INVALID_CHECKUPS,
    DELETE_INVALID_PRODUCTS,
    FETCH_CART_BY_CODE,
    MERGE_CART,
    RECEIVED_CART,
    REMOVE_APPOINTMENT_REQUIRED_PRODUCTS,
    REPEAT_ORDER,
    REPLACE_CART,
    RESET_CART_STATE,
    RETRY_HOME_VISIT_CALCULATION,
    RETRY_OFFICE_CALCULATION,
    SET_APPOINTMENT,
    SET_BIOMATERIALS,
    SHARE_CART,
    TOGGLE_ONLINE_REGISTRATION,
    UPDATE_OFFICE_TYPE
} from 'redux/cart/actions';
import { CALCULATE_OFFICE, CLEAR, DELETE_PRODUCT, FETCH, RESTORE_PRODUCT } from 'redux/cart/actions';
import {
    APPOINTMENT_COOKIE_KEY,
    INVITRO_CART_COOKIE_KEY,
    INVITRO_CART_OFFICE_ID_COOKIE_KEY,
    INVITRO_CART_ORDER_ID_COOKIE_KEY,
    MINDBOX_CLEAR_CART
} from 'redux/cart/constants';
import { reorderDataSelector } from 'redux/cart/reorder.selectors';
import {
    cartByCodeSelector,
    cartDataAnalyzesArticlesSelector,
    cartDataAnalyzesSelector,
    cartDataCheckupsSelector,
    cartDataProductsIdsSelector,
    officeCalculationSelector,
    officeDataSelector,
    officeTypeSelector,
    onlinePaymentTypesSelector,
    onlineRegistrationEnabledSelector,
    paymentTypeSelector,
    productsIdsRequiredAppointmentSelector,
    productsSelector,
    selectedPatientSelector
} from 'redux/cart/selectors';
import { CLEAR_ACTIVE_CHECKUP_CITY, GET_ACTIVE_CITY } from 'redux/cities/actions';
import { CITY_GUID_COOKIE_KEY } from 'redux/cities/constants';
import { currentCheckupCitySelector, currentCitySelector, currentTerritorySelector } from 'redux/cities/selectors';
import { request } from 'redux/helpers';
import { CHOOSE_OFFICE, CLEAR_OFFICE, FETCH_ALL, SELECT_OFFICE } from 'redux/offices/actions';
import { OFFICE_STORAGE_KEY } from 'redux/offices/constants';
import { selectOffice } from 'redux/offices/sagas';
import { chosenOfficeSelector, selectedOfficeSelector } from 'redux/offices/selectors';
import { fetchAll as fetchProducts } from 'redux/products/sagas';
import { currentSelector } from 'redux/user/selectors';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import cookieService from 'services/CookieService';
import LocalStorageService from 'services/LocalStorageService';
import { isNotServiceOrCheckupType } from 'utils/classifierTypeUtils';
import { mindbox } from 'utils/mindboxUtils';

function* fetch() {
    const cart = yield getCart();
    yield put(FETCH.success({ data: cart }));
}

function* clearCart() {
    const user = yield select(currentSelector);
    const mainContactId = user?.mainContactId;
    yield put(CLEAR_ACTIVE_CHECKUP_CITY.base());
    yield cookieService.remove(INVITRO_CART_COOKIE_KEY);
    if (mainContactId) {
        mindbox(MINDBOX_CLEAR_CART, mainContactId);
    }
}

function* clearAppointment() {
    yield cookieService.remove(APPOINTMENT_COOKIE_KEY);
}

function* deleteProduct(action) {
    const cart = yield getCart();
    const { article } = action.meta;

    if (cart.products) {
        cart.products = cart.products.filter((product) => product.article !== article);
        yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));
        yield put(DELETE_PRODUCT.success({}, { ...action.meta }));
    }
}

function* restoreProduct(action) {
    const { article } = action.meta;
    const cart = yield getCart();
    const storeCart = yield select((state) => state.cart.data);
    const storeProducts = storeCart.products?.filter((product) => product.article === article || !product.deleted);
    cart.products = [];
    storeProducts.forEach((storeProduct) => {
        cart.products.push({
            article: storeProduct.article,
            price: storeProduct.price
        });
    });
    yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));
}

function* deleteInvalidAnalyzes() {
    const { analyzes } = yield select(productsSelector);
    const officeType = yield select(officeTypeSelector);
    const cartOffice = yield select(chosenOfficeSelector);
    const invalidProductsArticles = analyzes.invalid.map((product) => product.article);
    yield deleteCartProducts(invalidProductsArticles);

    if (officeType === OfficeType.OFFICE && analyzes?.hasValid > 0) {
        yield* selectOffice({
            meta: {
                officeId: cartOffice.office.id,
                productsIds: cartOffice.validProductsIds
            }
        });
    }
}

function* deleteInvalidCheckups() {
    const { checkups } = yield select(productsSelector);
    const invalidProductsArticles = checkups.invalid.map((product) => product.article);
    yield deleteCartProducts(invalidProductsArticles);
}

function* deleteCartProducts(articles) {
    if (articles) {
        const cart = yield getCart();
        cart.products = cart.products.filter((product) => !articles.includes(product.article));
        yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));
        yield put(DELETE_INVALID_PRODUCTS.success({}, { articles }));
    }
}

function* removeAppointmentRequiredProducts() {
    const { analyzes } = yield select(productsSelector);
    const appointmentProductIds = yield select(productsIdsRequiredAppointmentSelector);
    const appointmentProductArticles = analyzes.valid.filter((product) => appointmentProductIds.has(product.id)).map((product) => product.article);
    yield deleteCartProducts(appointmentProductArticles);
}

function* calculateCheckups(action) {
    const { authenticated } = yield select(authSelector);
    const checkups = yield select(cartDataCheckupsSelector);
    const activeCheckupCity = yield select(currentCheckupCitySelector);
    const data = {
        ...action.meta,
        checkups: checkups
            .filter((product) => !product.deleted)
            .map(({ id, quantity }) => {
                return {
                    productId: id,
                    quantity
                };
            }),
        cityId: activeCheckupCity.id
    };
    const calculate = authenticated ? cartClient.calculateCheckupsWithAuth : cartClient.calculateCheckups;
    yield request(CALCULATE_CHECKUPS, [calculate, data], data);
}

function* calculateOffice(action) {
    const { officeId, patientId, orderProductsKeys } = action.meta || {};
    const cartOffice = yield select(chosenOfficeSelector);
    const { authenticated } = yield select(authSelector);

    if (!cartOffice) {
        return;
    }

    const selectedPatient = yield select(selectedPatientSelector);
    const territory = yield select(currentTerritorySelector);
    const onlinePaymentTypes = yield select(onlinePaymentTypesSelector);
    const selectedPaymentType = yield select(paymentTypeSelector);
    const onlineRegistrationEnabled = yield select(onlineRegistrationEnabledSelector);
    let calculatedOrderProductsKeys = orderProductsKeys;

    if (!orderProductsKeys) {
        // Если ключи продуктов не переданы явно, то необходимо их рассчитать
        const { analyzes } = yield select(productsSelector);
        const officeProducts = (analyzes?.valid || []).filter((product) => !product.deleted).filter(isNotServiceOrCheckupType);
        const officeProductsIds = new Set(officeProducts.map((product) => product.id));

        const cartDataAnalyzes = yield select(cartDataAnalyzesSelector);
        const cartDataProductsIds = new Set(
            cartDataAnalyzes
                .filter((product) => product.id)
                .filter((product) => !product.deleted)
                .filter(isNotServiceOrCheckupType)
                .map((product) => product.id) || []
        );
        let calculatedOrderProducts = [];
        if (officeProducts.some((officeProduct) => officeProduct.selected && !cartDataProductsIds.has(officeProduct.id))) {
            // Если любой из выбранных продуктов офиса отсутствует в исходной корзине, значит продукты корзины были удалены.
            // Необходимо пересчитать заказ только с продуктами соответствующими составу исходной корзины
            calculatedOrderProducts = officeProducts.filter(isNotServiceOrCheckupType).filter((orderProduct) => cartDataProductsIds.has(orderProduct.id));
        } else {
            calculatedOrderProducts = officeProducts.filter(isNotServiceOrCheckupType);
        }
        // Если в корзине появились новые продукты, то необходимо их добавить в запрос
        const addedProductsIds = [...cartDataProductsIds].filter((id) => !officeProductsIds.has(id));
        addedProductsIds.forEach((id) => {
            calculatedOrderProducts.push({
                id
            });
        });

        const { checkups } = yield select(productsSelector);

        if (calculatedOrderProducts.length === 0 && checkups?.all?.length === 0) {
            yield put(CLEAR.base());
            return;
        }

        calculatedOrderProductsKeys = calculatedOrderProducts.map((orderProduct) => {
            return {
                biomaterialId: orderProduct.biomaterial?.id,
                productId: orderProduct.id
            };
        });
    }

    const patient = yield select(selectedPatientSelector);
    const calculate = authenticated && patient ? cartClient.calculateOfficeWithAuth : cartClient.calculateOffice;

    const { ONLINE, OFFICE } = PaymentType;

    const calculateAuthData = authenticated
        ? {
              onlineRegistration: onlineRegistrationEnabled,
              patientId: patientId || selectedPatient?.id,
              paymentType: onlinePaymentTypes[selectedPaymentType] ? ONLINE : OFFICE
          }
        : {};

    const data = {
        ...action.meta,
        officeId: officeId || cartOffice.office.id,
        orderProductsKeys: calculatedOrderProductsKeys,
        territory,
        ...calculateAuthData
    };

    if (data.orderProductsKeys.length > 0) {
        yield request(CALCULATE_OFFICE, [calculate, data], data);
    }
}

function* calculateOfficeSuccess(action) {
    // При пересечении профилей часть продуктов может пропасть из корзины,
    // другая же часть может добавиться. После расчёта корзины в МО необходимо
    // актуализировать данные корзины в соответствии с произошедшими изменениями

    // Получаем и группируем продукты корзины
    const cartDataAnalyzes = yield select(cartDataAnalyzesSelector);
    const cartProductsIds = new Set();
    const cartProductById = {};
    cartDataAnalyzes
        .filter((product) => product.id)
        .filter((product) => !product.deleted)
        .filter((product) => !product.classifierData || !product.classifierData?.service)
        .forEach((product) => {
            cartProductsIds.add(product.id);
            cartProductById[product.id] = product;
        });

    // Получаем и группируем продукты расчёта в МО
    const calculationResponse = action.payload.data;
    const officeProductsIds = new Set();
    const officeProductById = {};
    calculationResponse.orderProducts.forEach((orderProduct) => {
        const product = orderProduct.product;
        if ((!product.classifierData || !product.classifierData?.service) && orderProduct.selected) {
            officeProductsIds.add(product.id);
            officeProductById[product.id] = orderProduct;
        }
    });

    // Если после расчёта продукт пропал из корзины - удаляем его из store
    const removedIds = [...cartProductsIds].filter((id) => !officeProductsIds.has(id));
    for (const removedId of removedIds) {
        yield put(DELETE_PRODUCT.success({}, { ...cartProductById[removedId], force: true }));
    }

    // Если после расчёта продукт был добавлен в корзину - добавляем его в store
    const addedIds = [...officeProductsIds].filter((id) => !cartProductsIds.has(id));
    for (const addedId of addedIds) {
        const officeProduct = officeProductById[addedId];
        yield put(ADD_PRODUCT.success({}, { article: officeProduct.product.article, price: officeProduct.cost }));
    }
}

function* calculateHomeVisit() {
    const city = yield select(currentCitySelector);
    const productsIds = yield select(cartDataProductsIdsSelector);
    const data = {
        cityId: city.id,
        productsIds
    };
    yield request(CALCULATE_HOME_VISIT, [homeVisitClient.calculate, data], data);
}

function* retryOfficeCalculation() {
    const calculation = yield select(officeCalculationSelector);
    if (calculation.request) {
        yield put(CALCULATE_OFFICE.base(calculation.request));
    }
}

function* setBiomaterials(action) {
    const biomaterialByProduct = action.meta;
    const officeData = yield select(officeDataSelector);
    const orderProducts = officeData?.orderProducts || [];
    const data = {
        orderProductsKeys: orderProducts
            .filter((orderProduct) => !orderProduct.product.classifierData || !orderProduct.product.classifierData?.service)
            .map((orderProduct) => {
                return {
                    biomaterialId: biomaterialByProduct[orderProduct.product.id] || orderProduct.biomaterial?.id,
                    productId: orderProduct.product.id
                };
            })
    };
    yield put(CALCULATE_OFFICE.base(data));
}

function* toggleOnlineRegistration() {
    const onlineRegistrationEnabled = yield select(onlineRegistrationEnabledSelector);
    const office = yield select(selectedOfficeSelector);
    if (onlineRegistrationEnabled && office && !office.office.onlineRegistrationSupported) {
        yield put(CLEAR_OFFICE.base());
    }
}

function* checkAppointment() {
    const appointmentJson = cookieService.get(APPOINTMENT_COOKIE_KEY);
    try {
        const appointment = JSON.parse(appointmentJson);
        if (appointment && appointment.number) {
            yield put(SET_APPOINTMENT.base(appointment));
        }
    } catch (e) {
        yield put(CLEAR_APPOINTMENT.base());
    }
}

function* shareCart(action) {
    yield request(SHARE_CART, [cartClient.shareCart, action.meta], action.meta);
}

function* fetchCartByCode(action) {
    const cart = yield getCart();
    const isEmptyCart = !cart.products?.length;
    yield put(RECEIVED_CART.base());
    const { data } = yield request(FETCH_CART_BY_CODE, [cartClient.fetchCartByCode, action.meta], {
        prevProducts: cart.products || []
    });

    if (isEmptyCart) {
        cart.products = [];
        data?.products?.forEach((product) => {
            cart.products.push({
                article: product.article
            });
        });
    }

    yield LocalStorageService.removeItem(OFFICE_STORAGE_KEY);
    yield cookieService.remove(APPOINTMENT_COOKIE_KEY);
    yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));
    yield put(FETCH.success({ data: { products: cart.products } }));
    const articles = cart.products.map((el) => el.article);
    yield* fetchProducts({ meta: { articles } });

    if (isEmptyCart) {
        yield setCartByCode(data);
    }
}

function* setCartByCode(data) {
    const { cityId, type, officeId } = data || {};
    const productsIds = yield select(cartDataProductsIdsSelector);
    const productsArticles = yield select(cartDataAnalyzesArticlesSelector);

    if (cityId) {
        cookieService.set(CITY_GUID_COOKIE_KEY, cityId, 365);
        yield put(GET_ACTIVE_CITY.base());
    }

    if (type) {
        const types = {
            OFFICE: OfficeType.OFFICE,
            VND: OfficeType.HOME_VISIT
        };

        yield put(UPDATE_OFFICE_TYPE.base({ type: types[type] }));
    }

    if (officeId) {
        yield put(
            FETCH_ALL.base({
                articles: productsArticles,
                cityId
            })
        );

        yield put(
            SELECT_OFFICE.base({
                officeId,
                productsIds
            })
        );

        yield put(CHOOSE_OFFICE.base({ id: officeId }));
    }
}

function* repeatOrder(action) {
    const cart = yield getCart();
    const prevProducts = cart.products || [];
    const repeatOrderProducts = yield action.meta.repeatOrderProducts;

    if (cart.products?.length < 1 || !cart.products) {
        delete cart[INVITRO_CART_ORDER_ID_COOKIE_KEY];
        delete cart[INVITRO_CART_OFFICE_ID_COOKIE_KEY];

        cart.products = [];
        repeatOrderProducts.forEach((item) => {
            cart.products.push({
                article: item.article
            });
        });

        yield put(RESET_CART_STATE.base());
    }
    yield LocalStorageService.removeItem(OFFICE_STORAGE_KEY);
    yield cookieService.remove(APPOINTMENT_COOKIE_KEY);
    yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));
    yield put(CLEAR_OFFICE.base());
    yield put(REPEAT_ORDER.success({ data: { prevProducts: prevProducts, products: repeatOrderProducts } }));
    yield put(FETCH.success({ data: { products: cart.products } }));
}

function* mergeCart(action) {
    const cart = yield getCart();
    const repeatOrderProducts = yield action.meta.repeatOrderProducts;
    const cartByCode = yield select(cartByCodeSelector);
    const products = repeatOrderProducts || cartByCode?.products;
    products.forEach((product) => {
        if (cart.products?.filter((item) => item.article === product.article).length < 1) {
            cart.products.push({
                article: product.article
            });
        }
    });

    yield LocalStorageService.removeItem(OFFICE_STORAGE_KEY);
    yield cookieService.remove(APPOINTMENT_COOKIE_KEY);
    yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));

    if (!cartByCode.type && !cartByCode.officeId) {
        yield put(CLEAR_OFFICE.base());
        yield put(RESET_CART_STATE.base());
    }

    yield put(CLEAR_REORDER_CART.base());
    yield put(MERGE_CART.success({ data: { actionCart: 'MERGE' } }));
    yield put(FETCH.success({ data: { products: cart.products } }));
    yield setCartByCode(cartByCode);
}

function* replaceCart(action) {
    const cart = {};
    cart.products = [];
    const cartByCode = yield select(cartByCodeSelector);
    const repeatOrderProducts = yield action.meta.repeatOrderProducts;
    const products = repeatOrderProducts || cartByCode?.products;
    products.forEach((product) => {
        cart.products.push({
            article: product.article
        });
    });
    yield LocalStorageService.removeItem(OFFICE_STORAGE_KEY);
    yield cookieService.remove(APPOINTMENT_COOKIE_KEY);
    yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));

    if (!cartByCode.type && !cartByCode.officeId) {
        yield put(CLEAR_OFFICE.base());
        yield put(RESET_CART_STATE.base());
        yield put(CLEAR_HOME_VISIT_DATA.base());
        yield put(CLEAR_OFFICE_DATA.base());
    }

    yield put(CLEAR_CHECKUPS_DATA.base());
    yield put(CLEAR_REORDER_CART.base());
    yield put(REPLACE_CART.success({ data: { actionCart: 'REPLACE' } }));
    yield put(FETCH.success({ data: { products: cart.products } }));
    yield setCartByCode(cartByCode);
}

export const getCart = () => {
    const cart = cookieService.get(INVITRO_CART_COOKIE_KEY);
    try {
        return JSON.parse(cart);
    } catch (e) {
        return {};
    }
};

export const getCartOrderId = () => {
    const cart = cookieService.get(INVITRO_CART_COOKIE_KEY);
    try {
        return JSON.parse(cart)[INVITRO_CART_ORDER_ID_COOKIE_KEY] || null;
    } catch (e) {
        return null;
    }
};

export const getCartOfficeId = () => {
    const cart = cookieService.get(INVITRO_CART_COOKIE_KEY);
    try {
        return JSON.parse(cart)[INVITRO_CART_OFFICE_ID_COOKIE_KEY] || null;
    } catch (e) {
        return null;
    }
};

function* addRecommendedProduct(action) {
    const { product, cost } = action.meta;
    const cart = yield getCart();

    cart.products.push({
        article: product.article,
        price: cost
    });
    yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));
    yield put(ADD_PRODUCT.success({}, { recommendationProduct: true, rproduct: { ...action.meta } }));
    yield put(CALCULATE_OFFICE.base());
}

function* addProduct(action) {
    const { product } = action.meta;
    const cart = yield getCart();
    const newCart = {
        products: cart.products?.length > 0 ? [...cart.products] : []
    };
    newCart.products.push({
        article: product.article
    });
    yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(newCart));
    yield put(ADD_PRODUCT.success({}, { article: product.article }));
}

function* addRecommendedProfile(action) {
    const { profile, cost } = action.meta;
    const cart = yield getCart();
    cart.products = cart.products.filter((product) => product.article !== profile.article);
    cart.products.push({
        article: profile.article,
        price: cost
    });

    yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));
    yield put(ADD_PRODUCT.success({}, { recommendationProfile: true, rprofile: { ...action.meta } }));
    yield put(CALCULATE_OFFICE.base());
}

function* checkProductsAvailable(action) {
    yield request(CHECK_PRODUCTS_AVAILABLE, [cartClient.checkProductsAvailable, action.meta]);
}

function* checkReorderAvailability() {
    const orderId = yield getCartOrderId();
    const officeId = yield getCartOfficeId();
    yield request(CHECK_REORDER_AVAILABILITY, [cartClient.checkReorderAvailability, { officeId, orderId }]);
    const reorderData = yield select(reorderDataSelector);
    if (reorderData.available) {
        yield put(CALCULATE_REORDER.base());
    } else {
        yield put(CLEAR_REORDER_CART.base());
    }
}

function* calculateReorder(action) {
    const orderId = yield getCartOrderId();
    const officeId = yield getCartOfficeId();
    const territory = yield select(currentTerritorySelector);
    const productIds = yield select(cartDataProductsIdsSelector);

    if (productIds.length > 0) {
        const data = {
            ...action.meta,
            officeId,
            orderId,
            productIds,
            territory
        };

        yield request(CALCULATE_REORDER, [cartClient.calculateReorder, data], data);
    }
}

function* calculatePaymentPreorder(action) {
    yield request(CALCULATE_PAYMENT_PREORDER, [cartClient.calculatePaymentPreorder, action.meta], action.meta);
}

function* calculatePaymentExternalPreorder(action) {
    yield request(CALCULATE_EXTERNAL_PREORDER, [cartClient.calculatePaymentExternalPreorder, action.meta], action.meta);
}

function* calculatePaymentExternalReorder(action) {
    yield request(CALCULATE_EXTERNAL_REORDER, [cartClient.calculatePaymentExternalReorder, action.meta], action.meta);
}

function* clearReorderCart(action) {
    const cart = yield getCart();
    const articles = yield action?.meta?.articles;

    delete cart[INVITRO_CART_ORDER_ID_COOKIE_KEY];
    delete cart[INVITRO_CART_OFFICE_ID_COOKIE_KEY];
    yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));

    if (articles?.length > 0) {
        yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify({ products: articles }));
    }
}

export default function* cartSagas() {
    yield all([
        takeLatest(FETCH.BASE, fetch),
        takeLatest(CLEAR.BASE, clearCart),
        takeLatest(CLEAR_REORDER_CART.BASE, clearReorderCart),
        takeLatest(DELETE_PRODUCT.BASE, deleteProduct),
        takeLatest(RESTORE_PRODUCT.BASE, restoreProduct),
        takeLatest(DELETE_INVALID_CHECKUPS.BASE, deleteInvalidCheckups),
        takeLatest(DELETE_INVALID_ANALYZES.BASE, deleteInvalidAnalyzes),
        takeLatest(REMOVE_APPOINTMENT_REQUIRED_PRODUCTS.BASE, removeAppointmentRequiredProducts),
        takeLatest(CALCULATE_OFFICE.BASE, calculateOffice),
        takeLatest(CALCULATE_OFFICE.SUCCEEDED, calculateOfficeSuccess),
        takeLatest(CALCULATE_EXTERNAL_PREORDER.BASE, calculatePaymentExternalPreorder),
        takeLatest(CALCULATE_EXTERNAL_REORDER.BASE, calculatePaymentExternalReorder),
        takeLatest(CALCULATE_HOME_VISIT.BASE, calculateHomeVisit),
        takeLatest(CALCULATE_CHECKUPS.BASE, calculateCheckups),
        takeLatest(CHECK_REORDER_AVAILABILITY.BASE, checkReorderAvailability),
        takeLatest(CALCULATE_REORDER.BASE, calculateReorder),
        takeLatest(CALCULATE_PAYMENT_PREORDER.BASE, calculatePaymentPreorder),
        takeLatest(RETRY_OFFICE_CALCULATION.BASE, retryOfficeCalculation),
        takeLatest(RETRY_HOME_VISIT_CALCULATION.BASE, calculateHomeVisit),
        takeLatest(SET_BIOMATERIALS.BASE, setBiomaterials),
        takeLatest(TOGGLE_ONLINE_REGISTRATION.BASE, toggleOnlineRegistration),
        takeLatest(CHECK_APPOINTMENT.BASE, checkAppointment),
        takeLatest(CLEAR_APPOINTMENT.BASE, clearAppointment),
        takeLatest(SHARE_CART.BASE, shareCart),
        takeLatest(FETCH_CART_BY_CODE.BASE, fetchCartByCode),
        takeLatest(MERGE_CART.BASE, mergeCart),
        takeLatest(REPLACE_CART.BASE, replaceCart),
        takeLatest(REPEAT_ORDER.BASE, repeatOrder),
        takeLatest(ADD_RECOMMENDED_PRODUCT.BASE, addRecommendedProduct),
        takeLatest(ADD_RECOMMENDED_PROFILE.BASE, addRecommendedProfile),
        takeLatest(ADD_PRODUCT.BASE, addProduct),
        takeLatest(CHECK_PRODUCTS_AVAILABLE.BASE, checkProductsAvailable)
    ]);
}
