import cn from 'classnames';
import Button from 'components/button/Button';
import useYandexMetrika from 'hooks/useYandexMetrika';
import messages from 'page/orders/component/authBlock/AuthBlockMessages';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import styles from './AuthBlock.module.css';

const AuthBlock = ({ vnd = false }) => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const yandexMetrika = useYandexMetrika();

    const handleClickAuth = (action?: 'registration') => {
        yandexMetrika.push({
            event: 'YM_event',
            event_id: 'id-v1-e64',
            event_cat: 'confirmation',
            action: 'clickElement',
            event_param: { be: 'order_registration', nb: formatMessage(messages.title), ne: formatMessage(messages.registrationButton) }
        });

        navigate('/login', { state: { action: action, referrer: location } });
    };

    return (
        <div>
            <div className={styles.authBlockContent}>
                <h2 className={cn({ [styles.vndTitle]: vnd })}>{formatMessage(vnd ? messages.vndTitle : messages.title)}</h2>
                <Button className={styles.authBlockButton} onClick={() => handleClickAuth()}>
                    {formatMessage(messages.loginButton)}
                </Button>
                <Link
                    to={'/login'}
                    className={styles.authBlockLink}
                    onClick={(event) => {
                        event.preventDefault();
                        handleClickAuth('registration');
                    }}
                >
                    {formatMessage(messages.registrationButton)}
                </Link>
            </div>
        </div>
    );
};

export default AuthBlock;
