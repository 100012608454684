import { useAppDispatch, useAppSelector } from 'app/Hooks';
import Button from 'components/button/Button';
import ButtonLink from 'components/button/link/ButtonLink';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Modal from 'components/modal/Modal';
import useYandexMetrika from 'hooks/useYandexMetrika';
import React, { FC, Fragment, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { clearActionCart, mergeCart, replaceCart } from 'redux/cart/actions';
import { cartDataProductsArticlesSelector } from 'redux/cart/selectors';
import { getSetArticles } from 'services/GTMService';
import { ErrorResponse } from 'types/common';

import cartErrorIcon from './cartErrorIcon.svg';
import cartIcon from './cartIcon.svg';
import styles from './ChangeProductsCartModal.module.css';
import messages from './ChangeProductsCartModalMessages';

type TArticles = { article: string };

type TProps = {
    show: boolean;
    loading?: boolean;
    error?: ErrorResponse;
    repeatOrderProducts: TArticles[];
};

const ChangeProductsCartModal: FC<TProps> = ({ show, repeatOrderProducts, loading, error }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const yandexMetrika = useYandexMetrika();
    const cartDataProductsArticles = useAppSelector(cartDataProductsArticlesSelector);

    useEffect(() => {
        if (show) {
            const articles = getSetArticles(cartDataProductsArticles);

            yandexMetrika.push({
                event: 'YM_event',
                event_cat: 'cart',
                action: 'viewPopUp',
                event_id: 'id-v1-e49',
                event_param: { be: 'cart_replaceCart', ne: formatMessage(messages.title) },
                ecommerce: {
                    other: {
                        actionField: {
                            option: 'zamena korzuny'
                        },
                        products: articles
                    }
                }
            });
        }

        return () => {
            dispatch(clearActionCart());
        };
    }, [show]);

    const yaMetrikaPush = (action: 'cart_replace' | 'cart_merge' | 'cart_cancel', message: string, products: any[]) => {
        yandexMetrika.push({
            event: 'YM_event',
            event_cat: 'cart',
            event_id: 'id-v1-e50',
            action: 'clickElement',
            event_param: { be: action, nb: formatMessage(messages.title), ne: message },
            ecommerce: {
                other: {
                    actionField: {
                        option: 'vybor zameny korzuny'
                    },
                    products
                }
            }
        });
    };

    const handleCloseModal = () => {
        const articles = getSetArticles(cartDataProductsArticles);
        yaMetrikaPush('cart_cancel', formatMessage(messages.cancel), articles);
        navigate('/cart');
    };

    const renderError = (code: number) => {
        const typeError = code >= 500 ? 'server' : 'code';
        return (
            <Fragment>
                <div className={styles.top}>
                    <h3 className={styles.title}>
                        {typeError === 'server' && formatMessage(messages.serverError)}
                        {typeError === 'code' && formatMessage(messages.codeError)}
                    </h3>
                    <img alt='' src={cartErrorIcon} className={styles.cartErrorIcon} />
                </div>
                <div className={styles.buttons}>
                    {typeError === 'server' && (
                        <Fragment>
                            <ButtonLink onClick={() => handleCloseModal()}>{formatMessage(messages.cancel)}</ButtonLink>
                            <Button onClick={() => window.location.reload()}>{formatMessage(messages.retry)}</Button>
                        </Fragment>
                    )}
                    {typeError === 'code' && (
                        <Fragment>
                            <Button onClick={() => handleCloseModal()}>{formatMessage(messages.close)}</Button>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Modal closable={true} onClose={() => handleCloseModal()} overlayClassName={styles.overlayClassName} show={show}>
                <div className={styles.container}>
                    {error && renderError(error.status)}
                    {!error && (
                        <Fragment>
                            <div className={styles.top}>
                                <h3 className={styles.title}>{formatMessage(messages.title)}</h3>
                                <img alt='' src={cartIcon} className={styles.cartIcon} />
                            </div>
                            <div className={styles.buttons}>
                                <ButtonLink onClick={() => handleCloseModal()}>{formatMessage(messages.cancel)}</ButtonLink>
                                <ButtonLink
                                    onClick={() => {
                                        const articles = getSetArticles([...(cartDataProductsArticles || []), ...(repeatOrderProducts || [])]);
                                        yaMetrikaPush('cart_merge', formatMessage(messages.merge), articles);
                                        dispatch(mergeCart({ repeatOrderProducts }));
                                    }}
                                >
                                    {formatMessage(messages.merge)}
                                </ButtonLink>
                                <Button
                                    onClick={() => {
                                        const articles = getSetArticles(repeatOrderProducts);
                                        yaMetrikaPush('cart_replace', formatMessage(messages.replace), articles);
                                        dispatch(replaceCart({ repeatOrderProducts }));
                                    }}
                                >
                                    {formatMessage(messages.replace)}
                                </Button>
                            </div>
                        </Fragment>
                    )}
                </div>
            </Modal>
            <LoaderModal show={loading} />
        </Fragment>
    );
};

export default memo(ChangeProductsCartModal);
