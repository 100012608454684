import { useAppDispatch, useAppSelector } from 'app/Hooks';
import AlertWarning from 'components/alert/AlertWarning';
import LoaderModal from 'components/modal/loader/LoaderModal';
import { FlocktoryActions } from 'constants/flocktoryActions';
import { IKBBonusAccountStatus } from 'constants/ikbBonusAccountStatus';
import useFirstRender from 'hooks/useFirstRender';
import useFlocktory from 'hooks/useFlocktory';
import usePrevious from 'hooks/usePrevious';
import useYandexMetrika from 'hooks/useYandexMetrika';
// @ts-ignore
import { isEmpty, isEqual } from 'lodash';
import messages from 'page/cart/confirmation/CartConfirmationPageMessages';
import CheckupDeliveryBlock from 'page/cart/confirmation/checkupDeliveryBlock/CheckupDeliveryBlock';
import ConfirmationBlock from 'page/cart/confirmation/confirmationBlock/ConfirmationBlock';
import DeliveryBlock from 'page/cart/confirmation/deliveryBlock/DeliveryBlock';
import DescriptionBlock from 'page/cart/confirmation/descriptionBlock/DescriptionBlock';
import OnlineRegistrationBlock from 'page/cart/confirmation/onlineRegistrationBlock/OnlineRegistrationBlock';
import OrderBlock from 'page/cart/confirmation/orderBlock/OrderBlock';
import PatientBlock from 'page/cart/confirmation/patientBlock/PatientBlock';
import queryString from 'query-string';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { NOT_FOUND, WRONG_CODE } from 'redux/auth/errorCodes';
import { authSelector } from 'redux/auth/selectors';
import {
    calculateCheckups,
    calculateOffice,
    calculatePaymentExternalPreorder,
    calculatePaymentExternalReorder,
    calculatePaymentPreorder,
    calculateReorder,
    clearCart,
    clearReorderCart,
    resetCartState,
    selectPatient,
    setCartOrderType,
    setPaymentPreorderId
} from 'redux/cart/actions';
import { flocktoryDataSelector } from 'redux/cart/flocktory.selectors';
import {
    carConfirmationLoyaltyErrorSelector,
    cartAnalyzesSelector,
    cartCalculationErrorSelector,
    cartCheckupDeliverySelector,
    cartConfirmationDataSelector,
    cartConfirmationErrorSelector,
    cartConfirmationStatusSelector,
    cartCurrencySelector,
    cartOrderTypeSelector,
    cartPaymentPayloadSelector,
    cartSelector,
    cartServicesSelector,
    cartValidSelector,
    commonCalculationDataSelector,
    commonCalculationRequestSelector,
    commonCalculationResponseSelector,
    costSelector,
    externalErrorSelector,
    officeDataSelector,
    onlinePaymentTypesSelector,
    paymentPreorderIdSelector,
    paymentTypeSelector,
    productsSelector,
    reorderInvalidAnalyzesArticlesSelector,
    saleActionSelector,
    selectedPatientSelector
} from 'redux/cart/selectors';
import { clearDataState } from 'redux/orders/actions';
import { fetchPatientOnlineRegistrationData, fetchPatients } from 'redux/patient/actions';
import { mainPatientSelector, patientSelector, sortedByMainPatientsSelector } from 'redux/patient/selectors';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { currentSelector } from 'redux/user/selectors';
import { createSetProducts, DataLayer } from 'services/GTMService';
import { Certificate, Coupon } from 'types/common';

import arrowBack from './assets/arrow-back.svg';
import styles from './CartConfirmationPage.module.css';
import OfficeBlock from './officeBlock/OfficeBlock';
import ExternalPatientBlock from './patientBlock/ExternalPatientBlock';

const CartConfirmationPage: FC = () => {
    const yandexMetrika = useYandexMetrika();
    const flocktory = useFlocktory();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const action = useNavigationType();
    const paymentFormRef = useRef<HTMLFormElement>(null);
    const { formatMessage } = useIntl();
    const isFirstRender = useFirstRender();
    const params = useLocation();

    const { territory } = useAppSelector(currentTerritorySettingsSelector);
    const mainPatient = useAppSelector(mainPatientSelector);
    const cart = useAppSelector(officeDataSelector);
    const calculationRequest = useAppSelector(commonCalculationRequestSelector);
    const calculationResponse = useAppSelector(commonCalculationResponseSelector);
    const calculationData = useAppSelector(commonCalculationDataSelector);
    const externalError = useAppSelector(externalErrorSelector);
    const paymentPreorderId = useAppSelector(paymentPreorderIdSelector);
    const cartConfirmationData = useAppSelector(cartConfirmationDataSelector);
    const calculationError = useAppSelector(cartCalculationErrorSelector);
    const confirmationError = useAppSelector(cartConfirmationErrorSelector);
    const confirmationLoyaltyError = useAppSelector(carConfirmationLoyaltyErrorSelector);
    const auth = useAppSelector(authSelector);
    const user = useAppSelector(currentSelector);
    const patients = useAppSelector(sortedByMainPatientsSelector);
    const cartAnalyzes = useAppSelector(cartAnalyzesSelector);
    const services = useAppSelector(cartServicesSelector);
    const cost = useAppSelector(costSelector);
    const currency = useAppSelector(cartCurrencySelector);
    const saleAction = useAppSelector(saleActionSelector);
    const selectedPatient = useAppSelector(selectedPatientSelector);
    const selectedPaymentType = useAppSelector(paymentTypeSelector);
    const onlinePaymentTypes = useAppSelector(onlinePaymentTypesSelector);
    const cartOrderType = useAppSelector(cartOrderTypeSelector);
    const { onlineRegistrationEnabled } = useAppSelector(cartSelector);
    const { checkups } = useAppSelector(productsSelector);
    const checkupDelivery = useAppSelector(cartCheckupDeliverySelector);
    const cartValid = useAppSelector(cartValidSelector);
    const patient = useAppSelector(patientSelector);
    const flocktoryData = useAppSelector(flocktoryDataSelector);
    const { loading, success } = useAppSelector(cartConfirmationStatusSelector);
    const paymentPayload = useAppSelector(cartPaymentPayloadSelector);
    const fetchPatientsSuccess = patient.fetchAll.success;
    const invalidArticles = useAppSelector(reorderInvalidAnalyzesArticlesSelector);
    const prevSelectedPatient = usePrevious(selectedPatient);
    const prevPatients = usePrevious(patients);
    const queryParams = new URLSearchParams(params.search);
    const externalId = queryParams.get('id');
    const orderTypeFromURL = queryParams.get('orderType');

    const [marketingMechanicsOptions] = useState({
        isApplied: true,
        marketingMechanicsItems: []
    });

    useEffect(() => {
        if (orderTypeFromURL) {
            dispatch(setCartOrderType(orderTypeFromURL));
        }
    }, [orderTypeFromURL]);

    useEffect(() => {
        if (externalId) {
            fetchExternalOrder(externalId, territory);
        }
    }, [cartOrderType]);

    useEffect(() => {
        document.title = formatMessage(messages.pageTitle);
        const { validAnalyzes, validCheckups } = cartValid;
        const setProducts = createSetProducts(null, cartAnalyzes.products);
        if (action === 'PUSH') {
            const dataLayer = new DataLayer('checkout', 'step_4', 'oformlenie zakaza', '', {
                checkout: {
                    actionField: { option: 'oformlenie zakaza', step: 4 },
                    products: [...setProducts]
                }
            });
            dataLayer.push();
        }

        if (action === 'REPLACE') {
            const dataLayer = new DataLayer('checkout', 'step_4', 'Authorization', '', {
                checkout: {
                    actionField: { option: 'Authorization', step: 4 },
                    products: [...setProducts]
                }
            });
            dataLayer.push();
        }

        document.title = formatMessage(messages.pageTitle);

        if (!externalId && !validAnalyzes && !validCheckups && !cartOrderType.paymentPreorder) {
            navigate('/cart');
        } else if (!externalId) {
            if (!cartOrderType.reorder) {
                handleFetchPatients();
            }
            if (cartOrderType.checkups) {
                dispatch(calculateCheckups());
            }
            if ((cartOrderType.analyzes && !auth.authenticated) || cartOrderType.paymentPreorder) {
                handleCalculate();
            }
        }

        return () => {
            if (paymentPreorderId) {
                dispatch(setCartOrderType(null));
                dispatch(setPaymentPreorderId(null));
            }
        };
    }, [cartOrderType, externalId, externalError]);

    useEffect(() => {
        if (
            !externalId &&
            ((fetchPatientsSuccess && !isFirstRender) || (!!prevPatients && !isEqual(prevPatients, patients))) &&
            patients.length > 0 &&
            !cartOrderType.reorder &&
            !cartOrderType.paymentPreorder
        ) {
            if (onlineRegistrationEnabled) {
                dispatch(fetchPatientOnlineRegistrationData());
            }
            dispatch(selectPatient(mainPatient));
            handleCalculate(mainPatient.id);
        }
    }, [fetchPatientsSuccess, patients?.length]);

    useEffect(() => {
        if (prevSelectedPatient?.id && !cartOrderType.reorder && !cartOrderType.paymentPreorder) {
            const currentPatient = selectedPatient || mainPatient;
            if (currentPatient) {
                handleCalculate(currentPatient.id);
            }
        }
    }, [selectedPaymentType, selectedPatient?.id]);

    useEffect(() => {
        if (success) {
            const { id, patientId, orderType, orderNumber, onlineRegistration, onlinePayment, selectedPaymentType } = cartConfirmationData.response;
            const flocktoryDataAction = flocktoryData[FlocktoryActions.POST_CHECKOUT];
            flocktory.push(FlocktoryActions.POST_CHECKOUT, { ...flocktoryDataAction, order: { id: orderNumber, ...flocktoryDataAction.order } });
            const setProducts = createSetProducts(null, cartAnalyzes.products);

            if (cartOrderType.analyzes) {
                yandexMetrika.push({
                    event: 'addEcommerce',
                    event_id: 'id-v1-e48',
                    event_cat: 'confirmation',
                    action: 'sendSuccess',
                    event_param: { be: 'invitroConfirm', tt: yandexMetrika.paymentTypeText, ne: 'Заказ оформлен' },
                    ecommerce_funnel: 'Non-Interactions',
                    ecommerce_param: 'v invitro',
                    ecommerce_step: 'purchase',
                    pageType: 'ThankYouPage',
                    eNI: '1',
                    ecommerce: {
                        purchase: {
                            actionField: {
                                affiliation: 'lk3',
                                coupon: '',
                                id: orderNumber,
                                revenue: cartAnalyzes.totalCost,
                                tax: ''
                            },
                            products: [...setProducts]
                        }
                    }
                });
            }

            window.addEventListener('beforeunload', componentGracefulUnmount);
            if (!isEmpty(paymentPayload?.data)) {
                paymentFormRef?.current?.submit();
            } else if (paymentPayload?.redirectUrl) {
                location.replace(paymentPayload?.redirectUrl);
            } else {
                navigate(
                    `/cart/accepted?${queryString.stringify({ id, onlinePayment, onlineRegistration, orderType, patientId, source: selectedPaymentType })}`
                );
            }

            return () => {
                componentGracefulUnmount();
            };
        }
    }, [paymentPayload, success]);

    useEffect(() => {
        if (!!prevPatients && prevPatients.length < patients.length) {
            const setProducts = createSetProducts(null, cartAnalyzes.products);
            const dataLayer = new DataLayer('checkout', 'step_4', 'add patient', '', {
                checkout: {
                    actionField: { option: 'add patient', step: 4 },
                    products: [...setProducts]
                }
            });
            dataLayer.push();
        }
    }, [patients?.length]);

    const fetchExternalOrder = (id: string, territory: string) => {
        if (cartOrderType.externalReorder) {
            dispatch(
                calculatePaymentExternalReorder({
                    reorderId: id,
                    territory: territory
                })
            );
        } else if (cartOrderType.externalPreorder) {
            dispatch(
                calculatePaymentExternalPreorder({
                    preorderId: id,
                    territory: territory
                })
            );
        }
    };

    const componentGracefulUnmount = () => {
        const articles = [...invalidArticles];
        dispatch(clearCart());
        dispatch(clearReorderCart({ articles }));
        dispatch(resetCartState());
        dispatch(clearDataState());
        window.removeEventListener('beforeunload', componentGracefulUnmount);
    };

    const handleFetchPatients = () => {
        if (user) {
            dispatch(fetchPatients({ userId: user.id }));
        }
    };

    const calculate = (data: any = {}) => {
        let loyaltyOption = data?.loyaltyOption || calculationRequest?.loyaltyOption || { marketingMechanics: marketingMechanicsOptions };

        if (!data?.loyaltyOption && calculationResponse?.loyaltyError) {
            loyaltyOption = undefined;
        }

        if (cartOrderType.paymentPreorder) {
            const preorderId = paymentPreorderId;

            dispatch(
                calculatePaymentPreorder({
                    ...calculationRequest,
                    loyaltyOption,
                    preorderId,
                    territory
                })
            );
        } else if (cartOrderType.externalPreorder) {
            dispatch(
                calculatePaymentExternalPreorder({
                    ...calculationRequest,
                    loyaltyOption,
                    preorderId: externalId,
                    territory
                })
            );
        } else if (cartOrderType.externalReorder) {
            dispatch(
                calculatePaymentExternalReorder({
                    ...calculationRequest,
                    loyaltyOption,
                    reorderId: externalId,
                    territory
                })
            );
        } else if (cartOrderType.analyzes) {
            dispatch(
                calculateOffice({
                    ...calculationRequest,
                    ...data,
                    loyaltyOption
                })
            );
        } else if (cartOrderType.reorder) {
            dispatch(
                calculateReorder({
                    ...calculationRequest,
                    ...data,
                    loyaltyOption
                })
            );
        }
    };

    const getBonuses = () => {
        const bonus = saleAction?.bonus;
        return bonus?.status == IKBBonusAccountStatus.ACTIVE && bonus.applied ? { writeOff: bonus.appliedBonuses } : null;
    };

    const getDiscountCard = () => {
        const personalDiscount = saleAction?.personalDiscount;
        return personalDiscount ? { number: personalDiscount.number } : null;
    };

    const getEmployeeDiscount = () => {
        const employeeDiscount = saleAction?.employeeDiscount;
        return employeeDiscount ? { apply: true } : null;
    };

    const getCoupons = () => {
        return saleAction?.coupons
            ?.filter((el: any) => el.number)
            .map((coupon: Coupon) => {
                return {
                    number: coupon.number
                };
            });
    };

    const getCertificates = () => {
        return saleAction?.certificates
            ?.filter((el: any) => el.number)
            .map((certificate: Certificate) => {
                return {
                    number: certificate.number
                };
            });
    };

    const handleBonusesChanged = (value: any) => {
        calculate({
            loyaltyOption: {
                bonus: { writeOff: value },
                certificates: getCertificates(),
                coupons: getCoupons(),
                marketingMechanics: marketingMechanicsOptions
            }
        });
    };

    const handleCoupons = (data: any) => {
        calculate({
            loyaltyOption: {
                bonus: getBonuses(),
                certificates: getCertificates(),
                coupons: data,
                discountCard: getDiscountCard(),
                employeeDiscount: getEmployeeDiscount(),
                marketingMechanics: marketingMechanicsOptions
            }
        });
    };

    const handleCertificates = (data: any) => {
        calculate({
            loyaltyOption: {
                bonus: getBonuses(),
                certificates: data,
                coupons: getCoupons(),
                discountCard: getDiscountCard(),
                employeeDiscount: getEmployeeDiscount(),
                marketingMechanics: marketingMechanicsOptions
            }
        });
    };

    const handleCalculate = (patientId?: string) => {
        calculate({
            patientId
        });
    };

    const renderPaymentForm = () => {
        const redirectUrl = paymentPayload?.redirectUrl;
        const actionUrl = paymentPayload?.actionUrl;
        const action = redirectUrl || actionUrl;

        if (!action || !paymentPayload?.data) {
            return null;
        }

        return (
            <form ref={paymentFormRef} name='paymentForm' action={action} method='POST'>
                {Object.entries(paymentPayload.data).map(([key, value]) => {
                    return <input key={key} type='hidden' name={key} value={value as string} />;
                })}
            </form>
        );
    };

    const renderCalculationError = () => {
        if (calculationError || externalError) {
            return <AlertWarning content={formatMessage(messages.errorCalculate)} />;
        }
    };

    const renderLoyaltyError = () => {
        if (confirmationLoyaltyError) {
            return (
                <div className={styles.loyaltyError}>
                    <AlertWarning className={styles.loyaltyErrorText} content={confirmationLoyaltyError.message} />
                </div>
            );
        }
    };

    const renderConfirmationError = () => {
        if (confirmationError) {
            if (confirmationError?.status === WRONG_CODE) {
                return <AlertWarning content={confirmationError.message} />;
            }
            return <AlertWarning content={formatMessage(cartOrderType.checkups ? messages.errorCreateOrder : messages.errorCreatePreorder)} />;
        }
    };

    const renderOnlineRegistrationBlocks = () => {
        if (!onlineRegistrationEnabled || !auth.authenticated || !mainPatient) {
            return null;
        }

        return (
            <>
                <OnlineRegistrationBlock />
                <DeliveryBlock />
            </>
        );
    };

    const renderBackButton = () => {
        if (cartOrderType.externalPreorder || cartOrderType.externalReorder) {
            return null;
        }

        if (cartOrderType.paymentPreorder) {
            return (
                <>
                    <img src={arrowBack} alt='' />
                    <Link onClick={() => navigate(-1)} to={'/orders'}>
                        {formatMessage(messages.backToOrders)}
                    </Link>
                </>
            );
        }

        return (
            <>
                <img src={arrowBack} alt='' />
                <Link to={'/cart'}>{formatMessage(messages.backToCart)}</Link>
            </>
        );
    };

    if (calculationError?.status === NOT_FOUND && externalId) {
        navigate('/pageNotFound');
        return null;
    }

    return (
        <div className={styles.orderConfirmationContainer}>
            <div className={styles.orderConfirmation}>
                <div className={styles.orderConfirmationHeader}>
                    <div className={styles.linkToBasket}>{renderBackButton()}</div>
                    <div className={styles.pageTitle}>
                        <h1>{formatMessage(messages.title)}</h1>
                    </div>
                </div>
                {renderConfirmationError()}
                {renderCalculationError()}
                {!externalError && (
                    <>
                        <div className={styles.orderConfirmationBlock}>
                            <div className={styles.leftColumn}>
                                {renderLoyaltyError()}
                                <OrderBlock
                                    cost={cost}
                                    cart={cartAnalyzes}
                                    saleAction={saleAction}
                                    onlinePaymentTypes={onlinePaymentTypes}
                                    selectedPaymentType={selectedPaymentType}
                                    handleBonusesChanged={handleBonusesChanged}
                                    handleCoupons={handleCoupons}
                                    handleCertificates={handleCertificates}
                                    handleCalculate={handleCalculate}
                                    cartOrderType={cartOrderType}
                                    checkups={checkups}
                                    services={services}
                                    currency={currency}
                                    loading={loading}
                                    isExternal={!!externalId}
                                />
                                {cartOrderType.checkups && <CheckupDeliveryBlock checkupDelivery={checkupDelivery} />}
                                {externalId && <OfficeBlock />}
                                {externalId ? <ExternalPatientBlock /> : <PatientBlock />}
                                {renderOnlineRegistrationBlocks()}
                            </div>
                            <div className={styles.rightColumn}>
                                {<ConfirmationBlock externalPayment={cart.onlinePaymentConfigs} externalId={externalId} />}
                            </div>
                        </div>

                        <DescriptionBlock cartOrderType={cartOrderType} calculationData={calculationData} />
                    </>
                )}
            </div>
            {renderPaymentForm()}
            <LoaderModal show={loading} />
        </div>
    );
};

export default memo(CartConfirmationPage);
