//@ts-nocheck
import withRouter from 'adapters/WithRouter';
import cn from 'classnames';
import { OnlinePaymentType, PaymentType } from 'constants/paymentType';
import commonMessages from 'messages/CommonMessages';
import CardForm from 'page/cart/confirmation/cardForm/CardForm';
import messages from 'page/cart/confirmation/cardForm/cardForm.messages';
import styles from 'page/cart/confirmation/confirmationBlock/ConfirmationBlock.module.css';
import DefinePaymentProvider from 'page/cart/confirmation/confirmationBlock/DefinePaymentProvider';
import Payment from 'page/cart/confirmation/payment/Payment';
import { PaymentButton } from 'page/cart/confirmation/paymentButton/PaymentButton';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { SELECT_PAYMENT_TYPE } from 'redux/cart/actions';
import { CART_ORDER_TYPE } from 'redux/cart/constants';
import {
    cartAnalyzesSelector,
    cartConfirmableSelector,
    cartCurrencySelector,
    cartOrderTypeSelector,
    onlinePaymentTypesSelector,
    paymentPreorderIdSelector,
    paymentTypeSelector,
    paymentTypesSelector
} from 'redux/cart/selectors';
import { CREATE, CREATE_CHECKUP, CREATE_REORDER, EXTERNAL_PREORDER_PAY, EXTERNAL_REORDER_PAY, PREORDER_PAY } from 'redux/orders/actions';
import { mainPatientSelector } from 'redux/patient/selectors';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { currentSelector } from 'redux/user/selectors';
import { createSetProducts, DataLayer } from 'services/GTMService';

class ConfirmationBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            isFixed: false
        };
        this.confirmationBlockRef = React.createRef();
        this.handleValueChange = this.handleValueChange.bind(this);
        this.onConfirmation = this.onConfirmation.bind(this);
        this.handleSelectPaymentType = this.handleSelectPaymentType.bind(this);
        this.windowOnScroll = this.windowOnScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.windowOnScroll);
    }

    windowOnScroll() {
        const confirmationBlockRef = this.confirmationBlockRef.current;
        if (confirmationBlockRef && document.body.clientWidth > 768) {
            const parentNode = confirmationBlockRef.parentNode;
            const parentNodePosition = parentNode.getBoundingClientRect().top + document.body.scrollTop;
            if (parentNodePosition >= 0) {
                this.setState({ isFixed: false });
            } else if (document.body.clientHeight > confirmationBlockRef?.getBoundingClientRect().height) {
                this.setState({ isFixed: true });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.windowOnScroll);
    }

    handleSelectPaymentType(paymentType) {
        this.props.selectPaymentType({ type: paymentType });
    }

    render() {
        const {
            intl,
            user,
            authenticated,
            confirmable,
            onlineRegistrationEnabled,
            mainPatient,
            paymentTypes,
            selectedPaymentType,
            onlinePaymentTypes,
            cartAnalyzes,
            cartOrderType,
            externalId
        } = this.props;

        const { isFixed } = this.state;

        const paymentButton = (
            <PaymentButton
                intl={intl}
                user={user}
                message={messages.payButton}
                mainPatient={mainPatient}
                confirmable={confirmable}
                cartOrderType={cartOrderType}
                authenticated={authenticated}
                onConfirmation={this.onConfirmation}
                onlineRegistrationEnabled={onlineRegistrationEnabled}
            />
        );

        return (
            <React.Fragment>
                <div className={cn({ [styles.confirmationBlock]: !isFixed, [styles.confirmationBlockFixed]: isFixed })} ref={this.confirmationBlockRef}>
                    <Payment
                        paymentTypes={paymentTypes}
                        onlinePaymentTypes={onlinePaymentTypes}
                        selectedPaymentType={selectedPaymentType}
                        handleSelectPaymentType={this.handleSelectPaymentType}
                    />
                    <DefinePaymentProvider
                        selectedPaymentType={selectedPaymentType}
                        cloudPayments={
                            <CardForm
                                intl={intl}
                                cart={cartAnalyzes}
                                confirmable={confirmable}
                                cartOrderType={cartOrderType}
                                onConfirmation={this.onConfirmation}
                            />
                        }
                        psb={paymentButton}
                        sberPay={paymentButton}
                        alfaPay={paymentButton}
                        loyalty={paymentButton}
                    />
                    {selectedPaymentType === PaymentType.OFFICE && (
                        <PaymentButton
                            intl={intl}
                            mainPatient={mainPatient}
                            confirmable={confirmable}
                            onConfirmation={this.onConfirmation}
                            onlineRegistrationEnabled={onlineRegistrationEnabled}
                        />
                    )}
                    {!externalId && !authenticated && (
                        <div className={styles.noticeContainer}>
                            <i className={styles.noticeArrow} />
                            <span>{intl.formatMessage(messages.notice)}</span>
                        </div>
                    )}
                    {!externalId && onlineRegistrationEnabled ? (
                        <p className={styles.onlineRegistrationWarning}>
                            <FormattedMessage {...commonMessages.onlineRegistrationWarning} />
                        </p>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }

    handleValueChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value });
    }

    onConfirmation(paymentPayload) {
        const {
            confirmable,
            cartAnalyzes,
            selectedPaymentType,
            cartOrderType,
            createCheckupOrder,
            createOrder,
            createReorder,
            preorderPay,
            externalPreorderPay,
            externalReorderPay,
            onlinePaymentType,
            paymentPreorderId,
            onlinePaymentTypes,
            currency,
            externalId
        } = this.props;

        const setProducts = createSetProducts(null, cartAnalyzes.products);
        const { phone, paymentEmail, cardCryptogramPacket, email } = paymentPayload;

        if (selectedPaymentType === PaymentType.OFFICE) {
            const dataLayer = new DataLayer('checkout', 'step_5', 'payment_MO', '', {
                checkout: {
                    actionField: { option: 'MO', step: 5 },
                    currencyCode: currency,
                    products: [...setProducts]
                }
            });

            dataLayer.push();
        }

        if (
            onlinePaymentTypes[selectedPaymentType] &&
            cartOrderType == CART_ORDER_TYPE.EXTERNAL_PREORDER &&
            cartOrderType == CART_ORDER_TYPE.EXTERNAL_REORDER
        ) {
            const dataLayer = new DataLayer('checkout', 'step_5', 'payment_online', '', {
                checkout: {
                    actionField: { option: 'online', step: 5 },
                    currencyCode: currency,
                    products: [...setExternalProducts]
                }
            });
            dataLayer.push();
        }

        if (onlinePaymentTypes[selectedPaymentType] && cartOrderType !== CART_ORDER_TYPE.CHECKUPS && cartOrderType !== CART_ORDER_TYPE.EXTERNAL_PREORDER) {
            const dataLayer = new DataLayer('checkout', 'step_5', 'payment_online', '', {
                checkout: {
                    actionField: { option: 'online', step: 5 },
                    currencyCode: currency,
                    products: [...setProducts]
                }
            });
            dataLayer.push();
        }

        if (cartOrderType.checkups) {
            createCheckupOrder({
                cardCryptogramPacket,
                email,
                paymentEmail,
                phone
            });
        }
        if (confirmable && cartOrderType.analyzes) {
            const isOnline = OnlinePaymentType[selectedPaymentType]?.length > 0;

            if (selectedPaymentType !== OnlinePaymentType.ALFA_PAY) {
                dataLayer.push({
                    ...(isOnline
                        ? {
                              event_id: 'id-v1-e33',
                              ecommerce_param: 'payment_online',
                              event_param: { be: 'invitroConfirmOnline', tt: 'Картой на сайте', ne: 'Подтвердить заказ' }
                          }
                        : {}),

                    ...(selectedPaymentType === PaymentType.OFFICE
                        ? {
                              event_id: 'id-v1-e34',
                              event_param: { be: 'invitroConfirmMO', tt: 'Оплатить на месте', ne: 'Подтвердить заказ' },
                              ecommerce_param: 'payment_MO'
                          }
                        : {}),
                    event: 'addEcommerce',
                    event_cat: 'confirmation',
                    action: 'clickButton',
                    ecommerce_funnel: 'checkout',
                    ecommerce_step: 'step_5',
                    pageType: '',
                    eNI: '1',
                    ecommerce: {
                        checkout: {
                            actionField: {
                                option: isOnline ? 'online' : 'MO',
                                step: 5
                            },
                            products: createSetProducts(null, cartAnalyzes.products)
                        }
                    }
                });
            } else {
                dataLayer.push({
                    event: 'YM_event',
                    event_id: 'id-v1-e46',
                    event_cat: 'confirmation',
                    action: 'clickButton',
                    event_param: { be: 'invitroConfirmPodeli', tt: 'Подели', ne: 'Подтвердить заказ' },
                    ecommerce: {
                        checkout: {
                            actionField: {
                                option: 'podeli',
                                step: 5
                            },
                            products: createSetProducts(null, cartAnalyzes.products)
                        }
                    }
                });
            }

            createOrder({
                cardCryptogramPacket,
                paymentEmail,
                phone
            });
        }

        if (confirmable && cartOrderType.reorder) {
            createReorder({
                cardCryptogramPacket,
                email,
                paymentEmail,
                phone
            });
        }

        if (confirmable && cartOrderType.paymentPreorder) {
            const preorderId = paymentPreorderId;
            preorderPay({
                cardCryptogramPacket,
                onlinePaymentType,
                paymentEmail,
                phone,
                preorderId
            });
        }
        if (confirmable && cartOrderType.externalPreorder) {
            const preorderId = externalId;
            externalPreorderPay({
                cardCryptogramPacket,
                onlinePaymentType,
                paymentEmail,
                phone,
                preorderId
            });
        }
        if (confirmable && cartOrderType.externalReorder) {
            const reorderId = externalId;
            externalReorderPay({
                cardCryptogramPacket,
                onlinePaymentType,
                paymentEmail,
                phone,
                reorderId
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        cartAnalyzes: cartAnalyzesSelector(state),
        cartOrderType: cartOrderTypeSelector(state),
        confirmable: cartConfirmableSelector(state),
        currency: cartCurrencySelector(state),
        mainPatient: mainPatientSelector(state),
        onlinePaymentTypes: onlinePaymentTypesSelector(state),
        onlineRegistrationEnabled: state.cart.onlineRegistrationEnabled,
        paymentPreorderId: paymentPreorderIdSelector(state),
        paymentTypes: paymentTypesSelector(state),
        selectedPaymentType: paymentTypeSelector(state),
        territorySettings: currentTerritorySettingsSelector(state),
        user: currentSelector(state)
    };
};

const mapDispatchToProps = {
    createCheckupOrder: CREATE_CHECKUP.base,
    createOrder: CREATE.base,
    createReorder: CREATE_REORDER.base,
    externalPreorderPay: EXTERNAL_PREORDER_PAY.base,
    externalReorderPay: EXTERNAL_REORDER_PAY.base,
    preorderPay: PREORDER_PAY.base,
    selectPaymentType: SELECT_PAYMENT_TYPE.base
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfirmationBlock)));
