//@ts-nocheck
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCartByCode } from 'redux/cart/actions';
import { INVITRO_CART_URL_CODE } from 'redux/cart/constants';
import { cartByCodeSelector } from 'redux/cart/selectors';
import CookieService from 'services/CookieService';

import ChangeProductsCartModal from '../components/changeProductsCartModal/ChangeProductsCartModal';

const CartByCodePage = () => {
    const { code } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cartByCode = useSelector(cartByCodeSelector);
    const loading = useSelector((state) => state.cart?.fetchCartByCode?.loading);
    const success = useSelector((state) => state.cart?.fetchCartByCode?.success);
    const error = useSelector((state) => state.cart.fetchCartByCode.error);
    const actionCart = useSelector((state) => state.cart.actionCart);

    useEffect(() => {
        CookieService.set(INVITRO_CART_URL_CODE, code);
        window.ym(5426650, 'reachGoal', 'ccLinkOpen', { URL: document.location.href });
        dispatch(fetchCartByCode(code));
    }, []);

    useEffect(() => {
        if (cartByCode?.prevProducts?.length < 1 && success) {
            navigate('/cart');
        }
    }, [cartByCode, success]);

    useEffect(() => {
        if (actionCart) {
            navigate('/cart');
        }
    }, [actionCart]);

    return <>{(cartByCode?.prevProducts?.length > 0 || error) && <ChangeProductsCartModal show={success} loading={loading} error={error} />}</>;
};

export default CartByCodePage;
