import { OnlinePaymentType, PaymentType } from 'constants/paymentType';

const { OFFICE } = PaymentType;
const { CLOUD_PAYMENTS, PSB, SBER_PAY, ALFA_PAY } = OnlinePaymentType;
import { useAppSelector } from 'app/Hooks';
import messages from 'page/cart/confirmation/payment/PaymentMessages';
import { useIntl } from 'react-intl';
import { paymentTypeSelector } from 'redux/cart/selectors';

declare const window: any;

interface Data {
    event: 'YM_event' | 'addEcommerce';
    event_cat: 'cart' | 'confirmation';
    action: 'clickElement' | 'clickButton' | 'clickTabs' | 'useCheckbox' | 'sendSuccess' | 'viewPage' | 'sendError' | 'viewPopUp';
    event_id:
        | 'id-v1-e24'
        | 'id-v1-e25'
        | 'id-v1-e26'
        | 'id-v1-e27'
        | 'id-v1-e31'
        | 'id-v1-e32'
        | 'id-v1-e45'
        | 'id-v1-e47'
        | 'id-v1-e48'
        | 'id-v1-e49'
        | 'id-v1-e50'
        | 'id-v1-e52'
        | 'id-v1-e53'
        | 'id-v1-e64';
    event_param: {
        ns?: 'Ошибка';
        nb?: string;
        ne?: string;
        tt?: string;
        be:
            | ''
            | 'methodVnd'
            | 'methodInvintro'
            | 'details_chooseOffice'
            | 'cart_toTests'
            | 'paymentMethodMO'
            | 'paymentMethodOnline'
            | 'paymentMethodPodeli'
            | 'invitroConfirm'
            | 'details_chooseMedOffice'
            | 'cart_replaceCart'
            | 'cart_replace'
            | 'cart_merge'
            | 'cart_cancel'
            | 'order_registration';
    };
    ecommerce_funnel?: 'checkout' | 'Non-Interactions';
    ecommerce_param?: 'vybor ofisa' | 'v invitro';
    ecommerce_step?: 'step_3' | 'purchase';
    eNI?: '1';
    pageType?: 'ThankYouPage';
    ecommerce?: {
        other?: {
            actionField: {
                option: 'zamena korzuny' | 'vybor zameny korzuny';
            };
            products: any[];
        };
        checkout?: {
            actionField: {
                option: 'vybor ofisa';
                step: 3;
            };
            products: any[];
        };
        confirm?: {
            actionField: {
                id: string;
            };
        };
        purchase?: {
            actionField: {
                affiliation: 'lk3';
                coupon: '';
                id: string;
                revenue: string;
                tax: '';
            };
            products: any[];
        };
    };
}

const useYandexMetrika = () => {
    const { formatMessage } = useIntl();
    const selectedPaymentType = useAppSelector(paymentTypeSelector);

    window.dataLayer = window.dataLayer || [];

    const getPaymentTypeText = (selectedPaymentType: string) => {
        switch (selectedPaymentType) {
            case CLOUD_PAYMENTS || PSB: {
                return formatMessage(messages.online);
            }
            case SBER_PAY: {
                return formatMessage(messages.sberPay);
            }
            case ALFA_PAY: {
                return formatMessage(messages.alfaPay);
            }
            case OFFICE: {
                return formatMessage(messages.office);
            }
        }
    };

    const push = (data: Data) => {
        window.dataLayer.push(data);
    };

    return {
        paymentTypeText: getPaymentTypeText(selectedPaymentType),
        push: (data: Data) => push(data)
    };
};

export default useYandexMetrika;
