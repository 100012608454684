import { useAppDispatch, useAppSelector } from 'app/Hooks';
import AlertWarning from 'components/alert/AlertWarning';
import Button from 'components/button/Button';
import Loader from 'components/loader/Loader';
import commonStyles from 'components/style/Common.module.css';
import PageTitle from 'components/title/PageTitle';
import commonMessages from 'messages/CommonMessages';
import PageNotFound from 'page/error/PageNotFound';
import PageLayout from 'page/layout/PageLayout';
import OrderStatus from 'page/orders/component/status/OrderStatus';
import ResultDownloadControl from 'page/orders/details/results/result/action/download/ResultDownloadControl';
import ResultEmailControl from 'page/orders/details/results/result/action/email/ResultEmailControl';
import ReorderControl from 'page/orders/details/results/result/action/reorder/ReorderControl';
import ResultViewControl from 'page/orders/details/results/result/action/view/ResultViewControl';
import TestResultsTable from 'page/orders/details/results/result/table/TestResultsTable';
import Birthday from 'page/partial/patient/card/birthday/Birthday';
import LastNameModal from 'page/results/key/modal/LastNameModal';
import messages from 'page/results/key/ResultsByKeyPageMessages';
import queryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { clearPatients } from 'redux/patient/actions';
import { clear, parseKey, searchResultByKey } from 'redux/result/actions';
import { supportPhoneSelector } from 'redux/startup/selectors';
import { getGenderMessage } from 'utils/messageUtils';
import { fullName } from 'utils/patientUtils';
import { formatDate, parseISO } from 'utils/timeUtils';

import styles from './ResultsByKeyPage.module.css';

const ResultsByKeyPage: FC = () => {
    const territory = useAppSelector((state) => currentTerritorySelector(state));
    const supportPhone = useAppSelector((state) => supportPhoneSelector(state));
    const { authenticated } = useAppSelector((state) => state.auth);
    const locale = useAppSelector((state) => state.i18n.currentLocale);
    const { data: parsedKey, success: parseKeySuccess, loading: parseLoading, error: parseKeyError } = useAppSelector((state) => state.result.parseKey);
    const {
        error: searchError,
        data: resultResponse,
        success: searchSuccess,
        request: searchRequest,
        loading: searchLoading
    } = useAppSelector((state) => state.result.search);
    const [searchParams] = useSearchParams();
    const { formatMessage } = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showLastNameModal, setShowLastNameModal] = useState<boolean>(false);
    const [invalidKey, setInvalidKey] = useState<boolean>(false);

    useEffect(() => {
        const lastName = searchParams.get('lastName');
        const key = searchParams.get('key');
        if (key) {
            dispatch(parseKey({ key }));
        } else if (lastName && parsedKey) {
            dispatch(searchResultByKey({ key: parsedKey.key, lastName }));
        } else {
            setInvalidKey(true);
        }

        return () => {
            dispatch(clear());
            dispatch(clearPatients());
        };
    }, []);

    useEffect(() => {
        const lastName = searchParams.get('lastName');
        if (parseKeySuccess) {
            if (parsedKey.lastNameRequired && !lastName) {
                setShowLastNameModal(true);
            } else {
                dispatch(searchResultByKey({ key: parsedKey.key, lastName }));
                setShowLastNameModal(false);
            }
        }
    }, [parseKeySuccess]);

    useEffect(() => {
        if (searchSuccess) {
            if (searchRequest.lastName) {
                const query = queryString.parse(location.search);
                if (query.lastName !== searchRequest.lastName) {
                    const searchString = queryString.stringify({
                        ...query,
                        lastName: searchRequest.lastName
                    });
                    navigate({
                        pathname: location.pathname,
                        search: searchString
                    });
                }
            }
            setShowLastNameModal(false);
        }
    }, [searchSuccess]);

    const confirmLastName = (data: { lastName: string }) => {
        const { lastName } = data;
        if (!lastName) {
            navigate('/');
        } else {
            dispatch(
                searchResultByKey({
                    key: parsedKey.key,
                    lastName
                })
            );
        }
    };

    const renderActions = (data: any) => {
        const result = data.result;
        if (!result.filesLocales || result.filesLocales.length === 0) {
            return null;
        }

        return (
            <div className={styles.actions}>
                <ReorderControl />
                <ResultViewControl
                    // @ts-ignore
                    territory={territory}
                    resultKey={data.key}
                    result={result}
                />
                <ResultDownloadControl territory={territory} resultKey={data.key} result={result} />
                <ResultEmailControl resultKey={data.key} result={result} />
            </div>
        );
    };

    const renderPatient = (patient: any) => {
        if (!patient) {
            return null;
        }
        return (
            <div className={styles.patient}>
                <div>{formatMessage(messages.clientLabelFormat, { client: fullName(patient) })}</div>
                <div>
                    {/* @ts-ignore */}
                    {formatMessage(commonMessages.birthdayLabelFormat, { birthday: <Birthday birthday={patient.birthday} locale={locale} /> })}
                </div>
                <div>{formatMessage(commonMessages.genderLabelFormat, { gender: formatMessage(getGenderMessage(patient.gender)) })}</div>
            </div>
        );
    };

    const renderContent = () => {
        if (parseKeyError) {
            return <AlertWarning content={parseKeyError?.message} />;
        } else if (!showLastNameModal && searchError) {
            return (
                <React.Fragment>
                    <AlertWarning content={searchError?.errors?.map((error: any) => error.message) || searchError?.message} />
                </React.Fragment>
            );
        } else if (!showLastNameModal && (parseLoading || searchLoading)) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            );
        } else if (resultResponse) {
            const result = resultResponse.result;
            return (
                <React.Fragment>
                    <div className={styles.contentContainer}>
                        <div className={styles.date}>{formatDate(parseISO(result.createdTime), locale, 'PP')}</div>
                        <div className={styles.inz}>{formatMessage(commonMessages.inzNumberFormat, { inz: result.inz })}</div>
                        {/* @ts-ignore */}
                        <OrderStatus order={result} supportPhone={supportPhone} locale={locale} />
                        {resultResponse.officeName && (
                            <div className={styles.office}>{formatMessage(commonMessages.officeNameFormat, { office: resultResponse.officeName })}</div>
                        )}
                    </div>
                    <div className={styles.contentContainer}>
                        <div className={styles.patientActionsBlock}>
                            {renderPatient(resultResponse.result.patient)}
                            {renderActions(resultResponse)}
                        </div>
                    </div>
                    <div className={styles.contentContainer}>
                        {result?.testResults?.length > 0 ? (
                            <React.Fragment>
                                {/* @ts-ignore */}
                                <TestResultsTable result={result} />
                                <div className={styles.resultsConsultationWarning}>{formatMessage(commonMessages.resultsConsultationWarning)}</div>
                            </React.Fragment>
                        ) : null}
                    </div>
                    {authenticated ? null : (
                        <div className={styles.loginContainer}>
                            <p>{formatMessage(messages.gotAccount)}</p>
                            <Button text={formatMessage(commonMessages.login)} onClick={() => navigate('/')} />
                        </div>
                    )}
                </React.Fragment>
            );
        }
    };

    if (invalidKey) {
        return <PageNotFound />;
    }
    return (
        <PageLayout
            main={
                <div className={commonStyles.generalContent}>
                    <PageTitle text={formatMessage(messages.title)} />
                    {renderContent()}
                    <LastNameModal show={showLastNameModal} confirm={confirmLastName} loading={parseLoading || searchLoading} error={searchError} />
                </div>
            }
        />
    );
};

export default ResultsByKeyPage;
