//@ts-nocheck
import { keyBy } from 'lodash';
import { FETCH_SETTINGS, INIT } from 'redux/startup/actions';
import { Store } from 'types/store';
import { getHostnameTerritory } from 'utils/hostNameUtils';

const initialState: Store.Startup.State = {
    initialized: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT.SUCCEEDED:
            return {
                ...state,
                initialized: true
            };
        case INIT.ERRORED:
            return {
                ...state,
                error: true
            };
        case FETCH_SETTINGS.SUCCEEDED:
            const territoryByDomain = getHostnameTerritory();
            const territorySettings = keyBy(action.payload?.data?.territorySettingsList, 'territory');
            const gtmID = territorySettings[territoryByDomain]?.gtmID;
            window.gtmInit(gtmID);
            return {
                ...state,
                settings: { ...action.payload.data, territorySettings }
            };
        case FETCH_SETTINGS.ERRORED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }
}
